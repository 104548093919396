<template>
  <div class="go-back">
    <span @click="back"><van-icon name="arrow-left" /></span>{{msg}}
  </div>
</template>

<script>
export default {
  name: 'goback',
  data() {
    return {
    
    }
  },
  created() {

  },
  props: {
    msg:"",
    url:""
  },
  methods: {
    back(){
        if(this.url){
          this.$router.push({path:this.url});
        }else {
          this.$router.go(-1);//返回上一层
        }

    },
  },
}
</script>

<style lang="scss" scoped>
.go-back {
  text-align: center;
  color: #131313;
  font-size: .34rem;
  line-height: 1.2rem;
  span{
    float: left;
    padding-left: .3rem;
  }
}
</style>
