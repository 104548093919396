var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "go-back" }, [
    _c(
      "span",
      { on: { click: _vm.back } },
      [_c("van-icon", { attrs: { name: "arrow-left" } })],
      1
    ),
    _vm._v(_vm._s(_vm.msg) + " ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }