<template>
  <div class="uploader">
    <van-uploader :after-read="afterRead" v-model="fileList"  accept="image/*,.pdf" multiple  />
    <div class="pic-box">
      <div class="img-list" v-for="(item,index) in box" >
        <div class="close"  @click="delUrl(item)" ><img src="../../assets/images/jj.png"></div>
        <img :src="item.url" @click="imgShow(index,'oss')" />
      </div>
<!--      <div class="img-list" v-for="(item,index) in fileList">-->
<!--        <div class="close" @click="removeUrl(index)"><img src="../../assets/images/jj.png"></div>-->
<!--        <img :src="item" @click="imgShow(index)">-->
<!--        <div></div>-->
<!--      </div>-->
      <div v-if="pdfList.length !=0">
        <div v-for="item in pdfList">
          <span @click="previewPDF(item)"><svg-icon icon-class="PDF"></svg-icon>{{item.name}} </span>
          <span class="close"  @click="delPdf(index,'oss',item)" ><img src="../../assets/images/jj.png"></span>
        </div>
      </div>
<!--      <div style="width: 100%">-->
<!--        <div style="width: 100%" v-for="(item,index) in fileBox":key="index">-->
<!--          {{item.name}}-->
<!--          <span class="close"  @click="delPdf(index)" ><img src="../../assets/images/jj.png"></span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="fileinput-button">-->
<!--        <span>+</span>-->
<!--        <input @change="ossUpload($event)" multiple  id="inputfile"  accept="image/*,.pdf"   type="file" ref="refName"/>-->
<!--      </div>-->
    </div>
    <van-image-preview v-model="show" :images="images" :startPosition="index-1"  @change="onChange">
      <template v-slot:index>第{{ index }}页</template>
    </van-image-preview>
  </div>
</template>
<script>
import Vue from 'vue'
import { ImagePreview} from 'vant';
Vue.use(ImagePreview);
export default {
  name: 'uploader',
  data: function () {
    return {
      fileList:[],
      box:[],
      show:false,
      images:[],
      index:0,
      fileBox:[],
      uploadList:[],
      imgList:[]
    }
  },
  props: {
    picList: {
      type: Array,
      default: [],
    },
    pdfType:'',
    pdfList: {
      type: Array,
      default: [],
    },

  },
  watch:{
    picList(n){
      this.box = n
      this.fileList = []
      this.imgList = []
    },
    pdfType(n){
      this.fileBox = []
    }
  },
  methods: {
    afterRead(file){
      console.log(file)
      console.log(file.length)
      let files = []
      if(file.length){
        for(let i = 0; i < file.length; i++){
          files.push(file[i].file)
        }
      }else {
        files.push(file.file)
      }

      console.log(files)
      this.$emit('getFiles',files)
    },
    ossUpload(e) {
       var file = e.target.files || e.dataTransfer.files;
        let imgBox = []
       for(let i =0 ; i < file.length; i++){
         if(file[i].type!='application/pdf'){
           this.getUrl(file[i])
           imgBox.push(file[i])
         }else {
           this.fileBox.push(file[i])
         }
       }
       this.imgList = imgBox
      this.$emit('getFiles',imgBox)
      this.$emit('getFilespdf',this.fileBox)
    },
    //转图片
    getUrl(file){
      var reader = new FileReader()
        var that = this 
        //  将文件读取为DataURL
        reader.readAsDataURL(file)
        // 读取成功调用方法
        reader.onload = e => {
          // e.target.result 获取 读取成功后的  文件DataURL
        that.fileList.push(e.target.result)
          // 如果要将图片上传服务器，就在这里调用后台方法
        }
    },
    //删除图片
    removeUrl(index){
      this.fileList.splice(index, 1);
      this.imgList.splice(index, 1);
      this.$emit('getFiles',this.imgList,'del')
    },
    delPdf(index,type,item){
      if(type =='oss'){
        this.$emit('delOssPDF',item)
      }else {
        this.fileBox.splice(index, 1);
        this.$emit('getFilespdf',this.fileBox,'del')
      }
    },
    //删除oss图片
    delUrl(item){
      this.$emit('delossUrl',item)
    },
    imgShow(index,type){
      this.show = true
      this.index = index+1
      if(type == 'oss'){
        this.images =[]
        for(let i = 0; i < this.box.length; i++){
          this.images.push(this.box[i].artworkMasterUrl)
        }
      }else{
        this.images= this.fileList
      }
    },
    onChange(index) {
      this.index = index+1;
    },
    previewPDF(item){
      this.$emit('previewPDF',item)
    }
  },
  created(){

  },
  mounted () {

  }
}

</script>
<style lang="scss" >
.uploader{
  overflow: hidden;
  padding: .4rem 0 .4rem .4rem;
  .pic-box{
    display:flex;
    flex-wrap:wrap;
    align-content:flex-start; //解决问题的样式
	
  }
}
.img-list{
  position: relative;
  margin: .1rem .2rem;
  .close{
    position: absolute;
    top:-.06rem;
    right: 0;
    img{
      width: .2rem;
      height: .2rem;
    }
  }
  img{
    width: 1.26rem;
    height: 1.48rem;
  }
}
 .fileinput-button {
  position: relative;
  display: inline-block;
   width: 1.26rem;
   height: 1.48rem;
  border: 1px dashed #C4C4C4;
  text-align: center;
   line-height: 1.48rem;
   margin: .1rem .2rem;
   border-radius: 4px;

   span{
     font-size: .36rem;
     font-weight: bold;
     color: #C4C4C4;
   }
}

.fileinput-button input{
    position: absolute;
    right: 0px;
    top: 0px;
  width: 1.06rem;
  height: 1.28rem;
    opacity: 0;
}
.close{
  img{
    width: .2rem;
    height: .2rem;
  }
}
</style>
<style>
.van-uploader__preview{
  margin: 0 24px 15px 0;
}
</style>