<template>
  <div>
    <van-field
      readonly
      clickable
      name="picker"
      :value="value"
      :label="title"
      placeholder="请选择"
      @click="showPicker = true"
      input-align="right"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Form ,Field,Popup} from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);
export default {
  name: 'popupshow',
  data() {
    return {
     value:"",
     showPicker:false
    }
  },
   components: {

   },
  props: {
    title: {
      type: String,
      default: '',
    },
    columns: '',
    val:null,
    fenqi: Boolean,
    zifu:null
  },
  computed: {
  },
   watch: {
   'val': function (n, o) {
    // console.log(n)
     this.val = n
     if(n == false){
       this.getFilter('1')
     }else {
       this.getFilter()
     }
   },
   'fenqi':function (){
     this.value =''
   },
   'zifu':function (n){
     this.value =''
   }
  },
  created() {
    let val = JSON.stringify(this.val)
    if(val == 'false'){
      this.getFilter('1')
    }else {
      this.getFilter()
    }

  },
   mounted() {

  },
  methods: {
    getFilter(type){
      if(type){
        if(this.columns){
          this.value = this.columns.filter(item => item.code === this.val)[0].name
        }
      }else {
        if(this.columns && this.val){
          this.value = this.columns.filter(item => item.code === this.val)[0].name
        }
      }

    },
    onConfirm(val){
      this.value = val.name;
      if(val.other){
         this.$emit('childByValue', val.code, val.other.compensateRatioOut)
      }else{
         this.$emit('childByValue', val.code)
      }
      this.showPicker =false
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
