var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uploader" },
    [
      _c("van-uploader", {
        attrs: {
          "after-read": _vm.afterRead,
          accept: "image/*,.pdf",
          multiple: ""
        },
        model: {
          value: _vm.fileList,
          callback: function($$v) {
            _vm.fileList = $$v
          },
          expression: "fileList"
        }
      }),
      _c(
        "div",
        { staticClass: "pic-box" },
        [
          _vm._l(_vm.box, function(item, index) {
            return _c("div", { staticClass: "img-list" }, [
              _c(
                "div",
                {
                  staticClass: "close",
                  on: {
                    click: function($event) {
                      return _vm.delUrl(item)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: require("../../assets/images/jj.png") }
                  })
                ]
              ),
              _c("img", {
                attrs: { src: item.url },
                on: {
                  click: function($event) {
                    return _vm.imgShow(index, "oss")
                  }
                }
              })
            ])
          }),
          _vm.pdfList.length != 0
            ? _c(
                "div",
                _vm._l(_vm.pdfList, function(item) {
                  return _c("div", [
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.previewPDF(item)
                          }
                        }
                      },
                      [
                        _c("svg-icon", { attrs: { "icon-class": "PDF" } }),
                        _vm._v(_vm._s(item.name) + " ")
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            return _vm.delPdf(_vm.index, "oss", item)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: require("../../assets/images/jj.png") }
                        })
                      ]
                    )
                  ])
                }),
                0
              )
            : _vm._e()
        ],
        2
      ),
      _c("van-image-preview", {
        attrs: { images: _vm.images, startPosition: _vm.index - 1 },
        on: { change: _vm.onChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function() {
              return [_vm._v("第" + _vm._s(_vm.index) + "页")]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.show,
          callback: function($$v) {
            _vm.show = $$v
          },
          expression: "show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }